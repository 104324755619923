<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
			<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
			<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"  :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
			<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"  @click="getData" :disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table style=" width:1200px" size="mini" :data="tableData" border="" stripe v-loading="loading" :show-overflow-tooltip="true">
				<el-table-column prop="pay_type_name" label="业务类型" align="center">
					<template slot-scope="scope">
						{{scope.row.agent_username?scope.row.agent_username:scope.row.pay_type_name}}
					</template>
				</el-table-column>
				<el-table-column label="交易金额" align="center">
					<template slot-scope="scope">
						<div>
							{{scope.row.add_money | tofixed}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="实际到账" align="center">
					<template slot-scope="scope">
						<div>
							{{scope.row.trader_gold | tofixed}}
						</div>
						<!-- {{scope.row.trader_gold | tofixed}} -->
					</template>
				</el-table-column>
				<el-table-column label="手续费" align="center">
					<template slot-scope="scope">
						<div>
							{{scope.row.fee_gold_tra | tofixed}}
						</div>
						<!-- {{scope.row.fee_gold_tra | tofixed}} -->
					</template>
				</el-table-column>
				
			
			</el-table>
		</template>

		<div class="flexX  mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>


	</div>
</template>

<script>
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData: [],	
				date: null,
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.orderReport()

		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderReport();
			},
		
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderReport()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderReport()
			},
			

			orderReport() {
				let data = {
					order_id: this.order_id,
					pay_type: this.payType,
					state: this.state,
					startdate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					page: this.page,
					size: this.pageSize,
				}
				// this.loading = true
				this.disLoading = true
				this.$api.orderReport(data).then(res => {
					this.disLoading = false
					this.loading = false;
					if (res.status === 1) {
						this.tableData = res.data.data.filter(item=>{
							// if(!item.agent_username){
								return item
							// }
						});
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			
		}
	};
</script>

<style>
</style>
